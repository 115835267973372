@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("theme.less");
@import url("./assets/fonts/fonts");

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
}

@layer components {
  .text-small-title-utility {
    @apply overflow-hidden text-[#03234D] text-sm font-medium leading-[18px] truncate;
  }

  .form-button-unactive {
    @apply !bg-gray-400 cursor-not-allowed;
  }

  .pro-list-item {
    @apply px-3 py-2 hover:cursor-pointer flex items-center gap-2 justify-between hover:bg-gray-100 transition-all duration-300 ease-in-out;
  }
  .shipment-list-item {
    @apply px-2 py-2 hover:cursor-pointer gap-3  hover:bg-gray-100 transition-all duration-300 ease-in-out;
  }

  .phone-number-container {
    @apply bg-gray-100 text-black font-medium px-4 py-2 rounded-lg shadow-sm;
  }
}
.font-thin-quicksand {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

html,
body {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  scrollbar-width: thin; /* For Firefox */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//Documentation front

.docs-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background: linear-gradient(to right, #2b0689, #822fd0);
  color: white;
  border-radius: 8px;
}

.component-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.component-description {
  color: #4b5563;
  margin-bottom: 1.5rem;
}

.demo-section {
  padding: 1.5rem;
  background-color: #f9fafb;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.code-section {
  background-color: #1f2937;
  color: #e5e7eb;
  padding: 1rem;
  border-radius: 6px;
  overflow-x: auto;
  font-family: monospace;
}

.code-section pre {
  margin: 0;
}

.props-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.props-table th,
.props-table td {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  text-align: left;
}

.props-table th {
  background-color: #f9fafb;
}

.badge {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-weight: 500;
}

.badge-required {
  background-color: #fee2e2;
  color: #991b1b;
}

.badge-optional {
  background-color: #e0e7ff;
  color: #3730a3;
}

.navigation {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 200px;
}

.nav-link {
  display: block;
  padding: 0.5rem;
  color: #4b5563;
  text-decoration: none;
  border-radius: 4px;
}

.nav-link:hover {
  background-color: #f3f4f6;
}

@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@primary: #2b0689;
@primary-color: #2b0689;

/* Style pour le dernier élément (actif) */
.ant-breadcrumb > nz-breadcrumb-item:last-child,
.ant-breadcrumb > nz-breadcrumb-item:last-child a {
  @apply text-primary;
}

/* Style pour le séparateur */
.ant-breadcrumb-separator {
  @apply text-gray-400;
}

/* Style pour les éléments non-cliquables */
.ant-breadcrumb span:not(a) {
  @apply text-gray-400;
}

.ant-tag {
  margin-right: 0 !important;
}

.ant-btn {
  border-radius: 14px !important;
}

.ant-input-number {
  padding: 0.3rem !important;
}
.ant-input {
  padding: 0.5rem !important;
}

.ant-select-selector,
.ant-input-number,
.ant-tag,
.ant-input {
  border-radius: 8px !important;
}

.ant-input-number,
.ant-input,
.ant-select {
  background-color: #ffffff;

  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: #2b0689;
    box-shadow: 0 0 0 2px rgba(51, 102, 255, 0.1);
  }

  &::placeholder {
    color: #8f9bb3;
  }
}
